<template>
  <div class="detail">
    <baseContainer back>
      <baseRow class="top">
        <baseCol :push="2">
          <div class="hd">
            <img :src="iconUrl" alt="" class="hd-img" v-show="iconUrl" />
            <div class="hd-main">
              <h3 class="template-name">{{ name }}</h3>
            </div>
          </div>
        </baseCol>
      </baseRow>
      <el-form label-width="120px">
        <baseSection name="基础信息">
          <baseRow justify="space-between">
            <baseCol>
              <el-form-item label="负责人：">{{ realName }}</el-form-item>
            </baseCol>
            <baseCol>
              <el-form-item label="配置参数：">{{ groupName }}</el-form-item>
            </baseCol>
            <baseCol>
              <el-form-item label="设备类型：">{{ deviceTypeName }}</el-form-item>
            </baseCol>
          </baseRow>
        </baseSection>
      </el-form>
    </baseContainer>
  </div>
</template>

<script>
import baseContainer from '@/components/container/base-container';
import baseSection from '@/components/section/base-section';
import baseRow from '@/components/row/base-row';
import baseCol from '@/components/col/base-col';
export default {
  props: {
    id: [Number, String]
  },
  data() {
    return {
      name: '',
      iconUrl: '',
      deviceTypeName: '',
      groupName: '',
      realName: ''
    };
  },
  created() {
    this.loadData();
  },
  methods: {
    async loadData() {
      const { name, iconUrl, deviceTypeName, groupName, realName } = await this.$apis.deviceTemplate.get({ templateId: this.id });
      this.name = name;
      this.iconUrl = process.env.VUE_APP_ICONIMG_URL + iconUrl;
      this.deviceTypeName = deviceTypeName;
      this.groupName = groupName;
      this.realName = realName;
    }
  },
  components: {
    baseContainer,
    baseSection,
    baseRow,
    baseCol
  }
};
</script>

<style lang="scss" scoped>
@import '@/common/style/var';
.top {
  margin-bottom: 40px;
}
.hd {
  display: flex;
  .hd-img {
    width: 90px;
    height: 90px;
  }
  .hd-main {
    margin: 15px 0 0 24px;
    .template-name {
      font-size: 18px;
    }
    // .file-list {
    //   width: 500px;
    //   height: 83px;
    //   // overflow-y: auto;
    //   // margin-top: 22px;
    //   .file-item {
    //     display: flex;
    //     align-items: center;
    //     .file-icon,
    //     .file-name {
    //       color: $--color-primary;
    //     }
    //     .file-name {
    //       overflow: hidden;
    //       text-overflow: ellipsis;
    //       white-space: nowrap;
    //       margin-left: 9px;
    //     }
    //   }
    // }
  }
}
</style>
